import "../styles/Logo.scss";

import React, { useContext, useEffect, useState } from "react";
import { motion } from "framer-motion";
import LogoReseauxContext from "../context/LogoReseauxContext.jsx";
import { Link } from "gatsby";

function Logo(props) {
  const LogoReseauxData = useContext(LogoReseauxContext);
  const [scrollToBottom, setScrollToBottom] = useState(false);

  useEffect(() => {
    const threshold = 0;
    let lastScrollY = window.pageYOffset;
    let ticking = false;
    const updateScrollDir = () => {
      const scrollY = window.pageYOffset;
      if (Math.abs(scrollY - lastScrollY) < threshold) {
        ticking = false;
        return;
      }
      setScrollToBottom(scrollY > lastScrollY ? true : false);
      lastScrollY = scrollY > 0 ? scrollY : 0;
      ticking = false;
    };
    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir);
        ticking = true;
      }
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <motion.nav
      className="logo-container"
      animate={{
        height: scrollToBottom ? "50px" : "110px",
      }}
    >
      <Link to="/" className="logo-inner">
        <img id="bonentendeur-logo" alt="logo" src={LogoReseauxData.logo} />
      </Link>
    </motion.nav>
  );
}

export default Logo;
